import Vue from 'vue'
import "./Add.less"

module.export = Vue.component('addaccountrequestmodal',
    {
        name: 'addaccountrequest',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                companyManagerSelection: 'findCompanyManager',
                AddAccountRequestModel: {
                    companyName: "",
                    allCountries: Object.assign([], this.data.allCountries),
                    selectedCountries: [],
                    countryCode: "",
                    catchAllEmailAddress: "",
                    remark: "",
                },
                ErrorMessage: "Could not add company",
                dataSource: {
                    data: window.preLoadedData.AllRights
                }
            };
        },
        mounted: function () {
            const _this = this;
            const countries = _this.AddAccountRequestModel.allCountries;
            kendo.jQuery("#countriesSelectorAddRequest").kendoMultiSelect({
                dataSource: countries,
                filter: "contains",
                tagTemplate: kendo.template($("#tagTemplate").html()),
                placeholder: "Select countries",
                autoClose: false,
                tagMode: "single",
                noDataTemplate: "No countries available",
                dataTextField: "Value",
                dataValueField: "Key",
                itemTemplate: `<div class="checkbox">
                        #= Value #
                        <input type="checkbox" name="checkbox" value="#= Key #"/>
                        <span class="checkmark"></span>
                    </div>`,
                change: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });

                    _this.AddAccountRequestModel.selectedCountries = selected;
                    if (selected.length == 0) {
                        this.tagList.children().each(function () {
                            this.remove();
                        });
                    }
                },
                open: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });
                }
            });

            $(".k-multiselect-wrap").append("<span class='glyphicon glyphicon-chevron-down'></span>");
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveAccountRequestButton").prop("disabled", true);
                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        $.ajax({
                            type: 'POST',
                            url: '/Settings/ManageAccounts/AddAccountRequest',
                            data: JSON.stringify({
                                companyName: _this.AddAccountRequestModel.companyName,
                                countryCode: _this.AddAccountRequestModel.countryCode,
                                countriesActive: _this.AddAccountRequestModel.selectedCountries,
                                catchAllEmailAddress: _this.AddAccountRequestModel.catchAllEmailAddress,
                                remark: _this.AddAccountRequestModel.remark
                            }),
                            success: function (data) {
                                if (data.success) {
                                    window.location.href = window.location.href.replace(/[\?#].*|$/, "?added=1");
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not add company: ${data.data.errorMessage}`,
                                            "isSuccess": false
                                        });
                                    $("#saveAccountRequestButton").removeAttr("disabled");
                                }
                            },
                            error: function (data) {
                                _this.addAlert(
                                    {
                                        "message": 'Could not add company due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveAccountRequestButton").removeAttr("disabled");
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    } else {
                        _this.addAlert(
                            {
                                "message": 'Please fill in all required fields correctly',
                                "isSuccess": false
                            });
                        $("#saveAccountRequestButton").removeAttr("disabled");
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.AddAccountRequest = Object.assign(this.data);
                // Enable save button again.
                $("#saveAccountRequestButton").removeAttr("disabled");
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        cb.checked = !cb.checked;
                        const user = grid.dataItem($(this).closest("tr[data-uid"));
                        user.Selected = cb.checked;
                        if (cb.checked) {
                            $(this.parentElement).addClass("state-selected");
                        } else {
                            $(this.parentElement).removeClass("state-selected");
                        }
                    }
                );
            }
        }
    });