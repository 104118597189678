import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import vSelect from 'vue-select'
import "../Grid/Grid.less"
import { getSubMemberName} from '../Shared/grid-utils'

if (document.getElementById('vue-archived-assets-overview')) {
    require("../Grid/GridConfiguration.js");
    require("../Actionbar/Actionbar.js");
    require("../Alerts/Alerts.js");
    require("../QueryableGrid/Grid.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    Vue.component('v-select', vSelect);
    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }

    new Vue({
        el: '#vue-archived-assets-overview',
        components: {},
        data: function () {
            return {
                actionbar: window.preLoadedData.Actionbar,
                GridConfiguration: window.preLoadedData.GridConfiguration,
                Grid: {
                    config: window.preLoadedData.GridConfiguration,
                    endpointQuery: "/LeasedAssets/Query?queryArchivedAssets=true",
                    endpointFilterValues: "/LeasedAssets/FetchFilterValues?queryArchivedAssets=true",                    
                    enableMultiSelect: true,
                    renderFieldValues: this.getCustomFieldValues,
                    renderRowActions: this.getCustomActions,
                    rowClickEvent: this.onRowClicked,
                    rowActionClickEvent: this.onRowActionClicked,
                    rowSelectedEvent: this.onRowSelectionChanged,
                    gridChangedEvent: this.onGridChanged,
                    gridFilteredEvent: this.onGridFiltered,
                    pageSizes: {
                        default: 20,
                        values: [10, 20, 30, 50]
                    },
                    selectAll: false,
                    search: undefined,
                    globalSelection: undefined,
                    selectedCount: undefined,
                    totalCount: undefined,
                    export: undefined,
                    customFilter: undefined,
                    refreshData: undefined,
                    refreshCurrentPage: undefined,
                    setActionbar: undefined,
                },
                alerts: [],
                selectedAssets: [],
                assetCount: 0,
                assetFilter: {},
                assetSort: {},
                HasBranding: window.hasBranding
            };
        },
        mounted: function () {
            const _this = this;
            _this.Grid.setActionbar(_this.$refs.actionbar);
        },
        created: function () {
            this.actionbar.searchVisible = true;
        },
        methods: {
            /**
             * ActionBar Events
             */
            actionbarChangeColumns: function (e) {
                $('#grid-configuration-modal').modal('show');
            },
            actionbarSelectAll: function (e) {
                if (this.Grid.selectAll) {
                    this.Grid.selectAll();
                }
            },
            actionbarSearch: function (e) {
                if (this.Grid.search) {
                    this.Grid.search(e);
                }
            },
            actionbarExport: function (e) {
                if (this.Grid.export) {
                    this.Grid.export();
                }
            },
            /**
            * Grid Events
            */
            loadColumns: function () {
                // this is not implemented here, the new QueryableGrid component handles this
                // this function is only here because the GridConfiguration component references this and we want to be backwards compatible
            },
            getCustomFieldValues: function (gridColumns) {
                const modifiedColumns = Object.assign([], gridColumns)
                for (let column of modifiedColumns) {
                    if (column.field === "portfolio") {
                        column.filterable = false;
                        column.sortable = false;
                    }
                }

                const fieldColumnsWithEmptyMessage = modifiedColumns.filter(x => typeof x.field !== "undefined" && ((x.field.startsWith("computedFields.")) || (x.field.startsWith("assetTypeFields["))));
                if (fieldColumnsWithEmptyMessage.length > 0) {
                    fieldColumnsWithEmptyMessage.forEach((potentialEmptyField) => {
                        const fieldIndex = modifiedColumns.findIndex(x => x.field === potentialEmptyField.field);
                        if (fieldIndex >= 0) {
                            modifiedColumns[fieldIndex].template = function (dataItem) {

                                if (potentialEmptyField.field.startsWith("computedFields")) {
                                    const computedFieldName = getSubMemberName(potentialEmptyField.field);
                                    const computedFields = dataItem["computedFields"];

                                    if (potentialEmptyField.type === 'number') {
                                        const numericValue = computedFields[computedFieldName];
                                        if (!numericValue) {
                                            return '<a class="anchor-info" href="\\#"><span class="icon-rowaction icon-rowaction-small icon-rowaction--dark icon-rowaction--info icon-rowaction--warning" title="Field could not be computed because one of the values is missing."></span></a>';
                                        }

                                        return computedFields[computedFieldName];
                                    }
                                } else if (potentialEmptyField.field.startsWith("assetTypeFields[")) {
                                    const assetTypeFieldName = getSubMemberName(potentialEmptyField.field);
                                    const assetTypeFields = dataItem["assetTypeFields"];  

                                    if (assetTypeFields[assetTypeFieldName] === undefined || assetTypeFields[assetTypeFieldName] === null) {
                                        return '<a class="anchor-info" href="\\#"><span class="icon-rowaction icon-rowaction-small icon-rowaction--dark icon-rowaction--info icon-rowaction--warning" title="This field is unavailable for this asset."></span></a>';
                                    }

                                    return assetTypeFields[assetTypeFieldName];
                                }
                                else 
                                {
                                    return dataItem[potentialEmptyField.field];
                                }
                            };
                        }
                    });
                }
                return modifiedColumns;
            },
            getCustomActions: function () {
                let customFields = [];

                customFields.push(
                    {
                        'title': ' ',
                        'filterable': false,
                        'template': '<a class="anchor-view custom-action" data-type="view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>',
                        'width': '120px'
                    });

                return customFields;
            },
            onRowClicked: function (item) {
                window.location.href = "./LeasedAssets/Details?id=" + item.id + "&overviewUrl=" + encodeURIComponent(window.location.pathname + window.location.hash);
            },
            onRowActionClicked: function (item, type) {
                if (!item)
                    return;

                if (type === "view") {
                    window.location.href = "./LeasedAssets/Details?id=" + item.id + "&overviewUrl=" + encodeURIComponent(window.location.pathname + window.location.hash);
                }
            },

            onGridChanged: function (selectedItems) {
                if (this.Grid.totalCount && this.Grid.selectedCount) {
                    const selectAll = document.getElementById('actionbarSelectAll');
                    if (selectAll.checked && this.Grid.selectedCount() < this.Grid.totalCount())
                        selectAll.checked = false;
                }
            },

            findActionBarButton: function (event) {
                return this.actionbar.ItemsRight.find(f => f.event === event) ? this.actionbar.ItemsRight.find(f => f.event === event) : this.actionbar.ItemsLeft.find(f => f.event === event);
            }
        }
    });
}