import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { toArray } from 'lodash';
import "../Grid/Grid.less"
import { fetchJson } from '../Shared/request-utils'
import { dllEmail } from '../Shared/validate-utils'

if (document.getElementById('vue-account-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./AddAccountRequest.js");
    require("./ApprovalAccounts.js");
    require("./Details.js");
    require("./EditAccountSettings.js");
    require("./Offboard.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);
    VeeValidate.Validator.extend('dllemail', dllEmail);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }

    new Vue({
        el: '#vue-account-overview',
        components: {},
        data: function () {
            return {
                GridConfiguration: window.preLoadedData.GridConfiguration,
                HasBranding: window.hasBranding,
                ShowSelectBoxes: window.preLoadedData.ShowSelectBoxes,
                AddModel: {
                    companyName: "",
                    allCountries: window.preLoadedData.AllCountries,
                    selectedCountries: [],
                    countryCode: "",
                    catchAllEmailAddress: "",
                    isDemoCompany: false,
                    companyManagerEmail: "",
                    companyManagerFirstName: "",
                    companyManagerLastName: "",
                    portalUsers: window.preLoadedData.PortalUsers
                },
                AddAccountRequestModel: {
                    companyName: "",
                    allCountries: window.preLoadedData.AllCountries,
                    selectedCountries: [],
                    countryCode: "",
                    catchAllEmailAddress: "",
                    remark: "",
                    portalUsers: window.preLoadedData.PortalUsers
                },
                EditModel: {
                    companyStatus: "",
                    AllCountries: window.preLoadedData.AllCountries,
                    selectedCountries: [],
                    countryCode: "",
                    isRejected: false
                },
                OffboardModel: {},
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Settings/ManageAccounts/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    sort: { field: "name", dir: "asc" },
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: {},
                actionbar: window.preLoadedData.Actionbar,

                search: {
                    visible: true
                },
                alerts: [],
                selectedAccounts: [],
                accountCount: 0,
            };
        },
        created: function () {
            this.loadColumns();

            this.actionbar.searchVisible = true;
        },
        mounted: function () {
            const grid = this.$refs.grid.kendoWidget();
            const _this = this;
            
            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    if (cb.checked) {
                        $(this.parentElement).addClass("state-selected");
                    } else {
                        $(this.parentElement).removeClass("state-selected");
                    }
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    if (dataItem) {
                        dataItem.selected = cb.checked;
                    } else {
                        grid.dataSource.data().forEach(item => item.selected = cb.checked );
                    }

                    _this.selectOnChange();
                    grid.refresh();
                });
        },
        methods: {
            /**
             * ActionBar Events
             */
            actionbarSelectAll: function (checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    grid.dataSource.data().forEach(i => i.selected = true);
                } else {
                    grid.dataSource.data().forEach(i => i.selected = false);
                }
                this.setHeaderSelectAll(grid, checked);
                this.selectOnChange();
                grid.refresh();
            },
            setHeaderSelectAll: function (grid, checked)
            {
                const cb = grid.element[0].querySelector(".k-grid-header .visual-checkbox input[type='checkbox']");
                cb.checked = checked;
                if (cb.checked) {
                    $(cb.parentElement.parentElement).addClass("state-selected");

                } else {
                    $(cb.parentElement.parentElement).removeClass("state-selected");
                }
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                let searchProperties = ["Name", "Country", "Status"];
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    searchProperties.forEach(function (x) {
                        if (x) {
                            filter.filters.push({
                                field: x,
                                operator: 'contains',
                                value: e
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            actionbarAddAccount: function (e) {
                $('#accountoverview-add-account').modal('show');
            },
            actionbarAddAccountRequest: function (e) {
                $('#accountoverview-add-account-request').modal('show');
            },
            actionbarApprovalAccounts: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);
                if (selected) {
                    this.selectedAccounts = selected;
                    this.accountCount = selected.length;
                    $('#accountoverview-approval-accounts').modal('show');
                }
            },
            actionbarExport: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.setOptions({
                    excel: {
                        allPages: true
                    }
                });
                grid.saveAsExcel();
            },

            /**
            * Grid Events
            */
            loadColumns: function (e) {
                var _this = this;
                this.columns = [
                    { 'field': 'name', 'title': 'Name', 'filterable': false, 'width': '70%' },
                    { 'field': 'country', 'title': 'Company Country Location', 'filterable': false, 'width': '70%' },
                    { 'field': 'countriesActive', 'title': 'Servicing Location Countries', 'filterable': false, 'width': '70%' },
                    { 'field': 'email', 'title': 'Catch-all Email Address', 'filterable': false, 'width': '70%' },
                    { 'field': 'companyManager', 'title': this.HasBranding ? 'DLL Company Manager' : 'Company Manager', 'filterable': false, 'sortable': false, 'width': '70%' },
                    { 'field': 'status', 'title': 'Status', 'filterable': false, 'width': '70%', 'template': '<span class="account-status #: status.toLowerCase() #">#: status #</span>' },
                    { 'field': 'dateCreated', 'title': 'Onboarded', 'filterable': false, 'width': '70%' }
                ];
                if (this.ShowSelectBoxes) {
                    this.columns.unshift({
                        'field': 'selected',
                        'title': ' ',
                        'filterable': false,
                        'sortable': false,
                        'headerTemplate':
                            `<div>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'template':
                            `<div #= selected ? \'class="state-selected"\' : "" #>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" #= selected ? \'checked="checked"\' : "" # />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'width': '40px'
                    });
                }
                this.columns.push(
                    { 
                        'title': ' ', 
                        'filterable': false, 
                        'template': function(dataItem) {
                            const actions = [];

                            if (dataItem.userHasAccess) {
                                actions.push('<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>');
                                if (dataItem.editCompanyAllowed) {
                                    actions.push('<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>');
                                }
                                if (dataItem.offboardCompanyAllowed) {
                                    actions.push('<a class="anchor-offboard" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Offboard"></span></a>');
                                }
                            }
                            return actions.join('');
                        },
                        'width': '120px' 
                    });
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            approvalChanged: function (message, eventType, item) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
                const approvalAccountsButton = this.findActionBarButton("ab-approval-accounts");
                if (approvalAccountsButton != null) {
                    approvalAccountsButton.enabled = false;
                }
            },
            accountOffboarded: function (message, eventType, item) {
                this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            gridOnDatabound: function (e) {
                var _this = this;
                const grid = this.$refs.grid.kendoWidget();

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                function (e) {
                    const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s); });
                    if (selectedItems.length === 1 && selectedItems[0].userHasAccess) {
                        window.location.href = "./ManageAccounts/Details?id=" + selectedItems[0].id;
                    }
                });

                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./ManageAccounts/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            fetchJson('./ManageAccounts/GetAccountDetails?id=' + selectedItems[0])
                            .then(
                                (result) => {
                                    _this.EditModel = Object.assign({}, result);
                                    _this.$refs.accountsettingsedit.show(_this.EditModel);
                                },
                                (error) => {
                                    console.error(error);
                                });
                        }
                    });

                grid.element.on('click', '.anchor-offboard',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s); });
                        if (selectedItems.length === 1) {
                            _this.OffboardModel = Object.assign({}, selectedItems[0]);
                            _this.$refs.offboardaccount.show(_this.OffboardModel);
                        }
                    });

                this.addTooltip(grid);
            },
            selectOnChange: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);

                const selectAll = document.getElementById('actionbarSelectAll');
                if (selectAll) {
                    if (selectAll.checked && selected.length < grid.tbody[0].rows.length) {
                        selectAll.checked = false;
                    }
                    
                    const approvalAccountsButton = this.findActionBarButton("ab-approval-accounts");
                    if (approvalAccountsButton != null) {
                        approvalAccountsButton.enabled = selected.length >= 1 && selected.every(i => i.approveCompanyAllowed);
                    }
                }
            },

            findActionBarButton: function (event) {
                return this.actionbar.ItemsRight.find(f => f.event === event) ? this.actionbar.ItemsRight.find(f => f.event === event) : this.actionbar.ItemsLeft.find(f => f.event === event);
            },

            /**
             * Alerts
             */
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
        }
    });
}