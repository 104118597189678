const dllEmail = {
    getMessage(field) {
        return `The ${field} must be a valid DLL email`;
    },
    validate(value) {
        const dllDomains = [
            "dllgroup.com",
            "delagelanden.com",
            "leasedirect.com"
        ];

        const dllDomainRegExp = '^[A-Za-z0-9._%+-]+@(' + dllDomains.join('|') + ')$';
        return RegExp(dllDomainRegExp).test(value);
    }
};

export { dllEmail }