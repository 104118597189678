import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { formatDateTime } from '../Shared/date-utils'

module.export = Vue.component('nonleasedassetedit',
    {
        name: "nonleasedassetedit",
        props: {
            data: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                overviewUrl: '',
                EditModel: structuredClone(this.data.Model), //create deep a copy of the object
                ShowError: false,
            };
        },
        mounted: function () {
            let url = new URL(window.location);
            if (url.searchParams.has('overviewUrl')) {
                url = decodeURIComponent(url.searchParams.get('overviewUrl'));
            }
            this.overviewUrl = url;
        },
        computed: {
            visibleAssetTypeFields: function () {
                return this.EditModel.AssetTypeFields.Value.filter(x => x.Editable == true);
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            autoFormat: function (evt) {
                if (evt && evt.target && evt.target.value) {

                    if (evt.target.value.indexOf(",") > 0) {
                        const matchingField = this.EditModel.AssetTypeFields.Value.find(x => x.Name === evt.target.name);

                        if (matchingField) {
                            matchingField.Value = evt.target.value.replaceAll(",", ".");

                            this.$validator.validate(evt.target.name, matchingField.Value).then((result) => {
                                if (result) {
                                    const validatedField = this.$validator.fields.find({
                                        name: evt.target.name
                                    });

                                    this.$validator.reset(validatedField);
                                }
                            });
                        }
                    }
                }
            },
            showHeader: function (id) {
                const headerElement = document.getElementById(id);

                if (!headerElement) {
                    return true;
                }

                const element = headerElement.nextElementSibling;

                if (element.nodeName == "LEGEND") {
                    return false;
                }
                return true;
            },
            validateBeforeSubmit: function () {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        const vueObject = this;
                        $("#saveButton").prop("disabled", true);

                        const formattedAssetTypeFields = vueObject.EditModel.AssetTypeFields.Value.reduce(function (accumulator, current) {
                            const name = current["Name"];
                            const type = current["Type"];
                            let value = current["Value"];

                            if (type === 'DateTime' && value != null && value.indexOf('T') > -1) {
                                value = formatDateTime(value);
                            }

                            accumulator[name] = value;
                            return accumulator;
                        },
                            {} /* Empty object, as initial value */);
                        $.ajax({
                            type: 'POST',
                            url: '/NonLeasedAssets/Edit',
                            data: JSON.stringify({
                                Id: vueObject.EditModel.Id,
                                MainCostCenter: vueObject.EditModel.MainCostCenter.Value,
                                CostCenter: vueObject.EditModel.CostCenter.Value,
                                DealerContactPerson: vueObject.EditModel.DealerContactPerson.Value,
                                User: vueObject.EditModel.User.Value,
                                CustomerReference: vueObject.EditModel.CustomerReference.Value,
                                AgreementLine: vueObject.EditModel.AgreementLine.Value,
                                AgreementNumber: vueObject.EditModel.AgreementNumber.Value,
                                AssetModel: vueObject.EditModel.AssetModel.Value,
                                SerialNumber: vueObject.EditModel.SerialNumber.Value,
                                AssetStatusCustomer: vueObject.EditModel.AssetStatusCustomer.Value,
                                AssetLocationStreet: vueObject.EditModel.AssetLocationStreet.Value,
                                AssetLocationCity: vueObject.EditModel.AssetLocationCity.Value,
                                InvoiceLocationStreet: vueObject.EditModel.InvoiceLocationStreet.Value,
                                InvoiceLocationCity: vueObject.EditModel.InvoiceLocationCity.Value,
                                InvoiceLocationCountry: vueObject.EditModel.InvoiceLocationCountry.Value,
                                Price: vueObject.EditModel.Price.Value,
                                OrderNumber: vueObject.EditModel.OrderNumber.Value,
                                CustomerGuaranteedResidualValue: vueObject.EditModel.CustomerGuaranteedResidualValue.Value,
                                BillingAmount: vueObject.EditModel.BillingAmount.Value,
                                StartDate: vueObject.EditModel.StartDate.Value,
                                EndDate: vueObject.EditModel.EndDate.Value,
                                ExtensionDate: vueObject.EditModel.ExtensionDate.Value,
                                AgreementTerminationDate: vueObject.EditModel.AgreementTerminationDate.Value,
                                ExtensionRent: vueObject.EditModel.ExtensionRent.Value,
                                Currency: vueObject.EditModel.Currency.Value,
                                ProductSKU: vueObject.EditModel.ProductSKU.Value,
                                ManufactureNumber: vueObject.EditModel.ManufactureNumber.Value,
                                ProductName: vueObject.EditModel.ProductName.Value,
                                Brand: vueObject.EditModel.Brand.Value,
                                Insurance: vueObject.EditModel.Insurance.Value,
                                Note: vueObject.EditModel.Note.Value,
                                AssetTypeFields: formattedAssetTypeFields
                            }),
                            success: function (data) {
                                if (data.success) {
                                    vueObject.close();
                                } else {
                                    vueObject.addAlert(
                                        {
                                            "message": `Could not edit asset: ${data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveButton").removeAttr("disabled");
                                }
                            },
                            error: function (data) {
                                vueObject.addAlert(
                                    {
                                        "message": 'Could not edit asset due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.ShowError = false;
                this.EditModel = structuredClone(this.data.Model);

                $('#nonleased-asset-edit-modal').modal('hide');

                // Enable save button again.
                $("#saveButton").removeAttr("disabled");
            }
        }

    });
