import Vue from 'vue'
let timer = 0;

module.export = Vue.component('addusertoportfoliomodal',
    {
        name: 'addusertoportfolio',
        props: {
            data: Array
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                AddUsersModel: Object.assign([], this.data), //create deep a copy of the object
                searchText: '',
                dataSource: {
                    data: window.detailModel.SelectPortfolioUsers
                },
                isSearching: false,
                columns: [
                    { 'field': 'Selected', 'title': ' ', 'filterable': false, 'template': '<div #= Selected ? \'class="state-selected"\' : "" #><a class="visual-checkbox" href="\\#"><input type="checkbox" #= Selected ? \'checked="checked"\' : "" # /></a></div>', 'width': '40px' },
                    { 'field': 'Name', 'title': 'User', 'filterable': false }
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            add: function (e) {
                e.preventDefault();
                const grid = this.$refs.grid.kendoWidget();
                const selectedUsers = grid.dataSource.data().filter(i => i.Selected).map(i => i.Id);
                const _this = this;
                _this.clearAlert();
                $("#addButton").prop("disabled", true);
                $.ajax({
                    type: 'POST',
                    url: '/Portfolios/SaveUsers',
                    data: JSON.stringify({
                        portfolioId: window.detailModel.PortfolioId,
                        selectedUsers: selectedUsers
                    }),
                    success: function (data) {
                        if (data.success) {
                            let url = new URL(location.href);
                            url.searchParams.delete('action');
                            window.location.href = url.href;
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add users: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#addButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add users due to a server error',
                                "isSuccess": false
                            });
                        $("#addButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.searchText = "";
                this.isSearching = false;
                this.AddUserModel = Object.assign({}, this.data);
                $('#edit-portfolio--add-user').modal('hide');

                // Enable save button again.
                $("#addButton").removeAttr("disabled");
            },
            search: function () {
                const _this = this;

                if (_this.searchText) {
                    window.clearTimeout(timer);
                    timer = window.setTimeout(() => {
                        _this.isSearching = true;
                        _this.applyFilters({ field: 'Name', operator: 'contains', value: _this.searchText });
                    }, 250);  
                } else {
                    _this.isSearching = false;
                    _this.applyFilters([]);
                }
            },
            applyFilters: function (filters) {
                this.$refs.grid.kendoWidget().dataSource.filter(filters);
            },
            clearSearch: function () {
                this.searchText = '';
                this.search();
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;
                grid.element.off('click', '.visual-checkbox');
                grid.element.on('click', '.visual-checkbox',
                    function (e) {
                        e.preventDefault();
                        const cb = this.querySelector("input[type='checkbox']");
                        cb.checked = !cb.checked;
                        const user = grid.dataItem($(this).closest("tr[data-uid"));
                        user.Selected = cb.checked;
                        if (cb.checked) {
                            $(this.parentElement).addClass("state-selected");
                        } else {
                            $(this.parentElement).removeClass("state-selected");
                        }
                    });
            }
        }
    });