import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import "./EditAccountSettings.less"

module.export = Vue.component('editaccountsettingsmodal',
    {
        name: 'editaccountsettings',
        props: {
            data: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                EditModel: Object.assign({}, this.data),
                CompanyStatusOptions: ['Active', 'Pending', 'Rejected', 'Offboarded']
            };
        },
        computed: {
            isRejected: function () {
                return this.EditModel.CompanyStatus == "Rejected"
            }
        },     
        mounted: function () {
            const _this = this;
            const countries = _this.EditModel.AllCountries;
            kendo.jQuery("#countriesSelectorEdit").kendoMultiSelect({
                dataSource: countries,
                value: _this.EditModel.SelectedCountries ?? [],
                filter: "contains",
                tagTemplate: kendo.template($("#tagTemplate").html()),
                placeholder: "Select countries",
                autoClose: false,
                tagMode: "single",
                noDataTemplate: "No countries available",
                dataTextField: "Value",
                dataValueField: "Key",
                itemTemplate: `<div class="checkbox">
                        #= Value #
                        <input type="checkbox" name="checkbox" value="#= Key #"/>
                        <span class="checkmark"></span>
                    </div>`,
                change: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });

                    _this.EditModel.SelectedCountries = selected;
                    if (selected.length == 0) {
                        this.tagList.children().each(function () {
                            this.remove();
                        });
                    }
                },
                open: function () {
                    const selected = this.value();
                    const items = this.listView.content.find("li");
                    items.each(function () {
                        const checkbox = $(this).find("input[type='checkbox']");
                        const value = checkbox.val();
                        checkbox.prop("checked", selected.indexOf(value) > -1);
                    });
                }
            });

            $(".k-multiselect-wrap").append("<span class='glyphicon glyphicon-chevron-down'></span>");
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            show: function (data) {
                const _this = this;
                this.EditModel = Object.assign({}, data);
                const countriesSelector = kendo.jQuery("#countriesSelectorEdit").getKendoMultiSelect();
                countriesSelector.value(this.EditModel.SelectedCountries);
                const items = countriesSelector.listView.content.find("li");
                items.each(function () {
                    const checkbox = $(this).find("input[type='checkbox']");
                    const value = checkbox.val();
                    checkbox.prop("checked", _this.EditModel.SelectedCountries.indexOf(value) > -1);
                });  
                $('#edit-account-settings-modal').modal('show');
            },
            save: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveSettingsButton").prop("disabled", true);

                const trimmedCompanyName = this.EditModel.CompanyName.trim();

                this.$validator.validateAll().then((isValid) => {
                    if (isValid) {
                        $.ajax({
                            type: 'PUT',
                            url: '/Settings/ManageAccounts/EditAccountSettings',
                            data: JSON.stringify({
                                AccountId: this.EditModel.AccountId,
                                CompanyName: trimmedCompanyName !== this.data.CompanyName ? trimmedCompanyName : null,
                                CompanyManager: this.EditModel.CompanyManager,
                                SelectedCompanyManager: this.EditModel.SelectedCompanyManager,
                                CatchAllEmail: this.EditModel.CatchAllEmail,
                                IsDemoCompany: this.EditModel.IsDemoCompany,
                                Email: this.EditModel.Email,
                                FirstContact: this.EditModel.FirstContact,
                                PhoneNumber: this.EditModel.PhoneNumber,
                                ProgramManager: this.EditModel.ProgramManager,
                                CountryCode: this.EditModel.CountryCode,
                                SelectedCountries: this.EditModel.SelectedCountries
                            }),
                            success: function (data) {
                                if (data.success) {
                                    _this.close();
                                    location.reload();
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not edit company settings: ${data.data}`,
                                            "isSuccess": false
                                        });
                                    $("#saveSettingsButton").removeAttr("disabled");
                                }
                            },
                            error: function () {
                                _this.addAlert(
                                    {
                                        "message": 'Could not edit company settings due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveSettingsButton").removeAttr("disabled");
                            },
                            contentType: "application/json",
                            dataType: 'json'
                        });
                    } else {
                        _this.addAlert(
                            {
                                "message": 'Could not edit company settings due to incorrect or incomplete fields.',
                                "isSuccess": false
                            });
                        $("#saveSettingsButton").removeAttr("disabled");
                    }
                });
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                sessionStorage.setItem("account.details.activeTab", 'general-info');
                $('#edit-account-settings-modal').modal('hide');
            }
        }
    });