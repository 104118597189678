import Vue from 'vue'
import { fetchJson } from '../Shared/request-utils'
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import VeeValidate from "vee-validate"
import "./Details.less"
import { dllEmail } from '../Shared/validate-utils'

if (document.getElementById('account-settings-details')) {


    require('./EditAccountSettings.js');
    require('./EditMailSettings.js');
    require('./EditAvailableFunctions.js');
    const alerts = require("../Alerts/Alerts.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);
    VeeValidate.Validator.extend('dllemail', dllEmail);

    new Vue({
        el: '#account-settings-details',
        components: {},
        data: {
            alerts: [],
            EditModel: window.detailModel,
            MailSettings: {
                selectedItem: null,
                dataSource: {
                    data: window.detailModel.MailSettings,
                    pageSize: 10
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                skip: 0,
                take: 20,
                columns: [
                    { 'field': 'EmailType', 'type': 'text',  'hidden': true, 'filterable': false },
                    { 'field': 'Title', 'type': 'text', 'title': 'Request type', 'filterable': false },
                    { 'field': 'Email', 'type': 'text', 'template': '#= Email ? Email : "No mail set up for country or company" #', 'title': 'Email', 'filterable': false },
                    { 'field': 'IsDefaultSetting', 'template': '#= IsDefaultSetting ? "Yes" : "No" #', 'type': 'text', 'title': 'Default setting', 'filterable': false },
                    {
                        'title': ' ',
                        'filterable': false,
                        'template': function (dataItem) {
                            const actions = [];
                            if (window.detailModel.EditCompanyAllowed) {
                                actions.push('<a class="anchor-edit" href="\\#"><a class="anchor-edit" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>');
                            }                            
                            return actions.join('');
                        },
                        'width': '120px'
                    }
                ]
            },
        },
        methods: {
            editAccount: function () {
                $('#edit-account-settings-modal').modal('show');
            },
            editFunctions: function () {
                $('#edit-functions-modal').modal('show');
            },
            mailSettingsGridOnDatabound: function (e) {
                const grid = this.$refs.mailsetupgrid.kendoWidget();
                const _this = this;

                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const setting = grid.dataItem($(this).closest("tr[data-uid"));
                        _this.MailSettings.selectedItem = Object.assign({}, setting);
                        _this.$refs.editmailsetup.show(_this.MailSettings.selectedItem);
                    });
            },
            refreshGrid: function (message) {
                let _this = this;
                const grid = _this.$refs.mailsetupgrid.kendoWidget();
                const currentDatasource = grid.dataSource;

                fetchJson('/Settings/ManageAccounts/GetAccountDetails?id=' + _this.EditModel.AccountId)
                    .then(
                        (result) => {
                            _this.EditModel = Object.assign({}, result);
                            currentDatasource.data(_this.EditModel.MailSettings);
                            grid.setDataSource(currentDatasource);

                            if (message) {
                                _this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                            }
                        },
                        (error) => {
                            console.error(error);
                        });
            },
            /**
             * Alerts
             */
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
        },
        mounted: function () {
            const activeTab = sessionStorage.getItem("account.details.activeTab");
            if (activeTab) {
                $("input[name='account-settings-tabs'][value='" + activeTab + "']").prop("checked", true);
            }
        }
    });
}