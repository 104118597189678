import Vue from 'vue'
import axios from 'axios';
import { formatDateTimeToDate } from '../Shared/date-utils';
import {  encodeAndCompressToHash, decodeAndDecompressFromHash } from '../Shared/grid-utils'

module.export = Vue.component('queryableGridComponent',
    {
        name: 'queryableGridComponent',
        props: {
            setup: Object,
        },
        components: {},
        data: function () {
            return {
                gridData: {
                    data: [],
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        pagedData: undefined,
                        formatFilters: undefined,
                        fetchPagedData: undefined,
                        restoreFromHash: undefined,
                        saveToHash: undefined,

                        read: async function (options) {
                            const _this = this;
                            this.formatFilters(options.data.filter);
                            const objectFromHash = await this.restoreFromHash(false, options.data.filter === undefined && options.data.sort === undefined && !this.pagedData.searchClearOut, !this.pagedData.isApplyingFilter, options);

                            this.pagedData.searchClearOut = false;

                            let pageToken = null;
                            if (!objectFromHash || !objectFromHash.pagedData || this.pagedData.isApplyingFilter) {
                                this.pagedData.offset.start = 1;
                                this.pagedData.tokens.values = [];
                                this.pagedData.tokens.index = -1;
                            } else {
                                pageToken = objectFromHash.pageToken;
                            }

                            if (this.pagedData.isApplyingFilter) {
                                this.pagedData.isApplyingFilter = undefined;
                            }

                            const sortingHasChanged = this.pagedData.previousSort && options.data.sort && this.pagedData.previousSort.length == 1 && options.data.sort.length == 1 && (this.pagedData.previousSort[0].dir !== options.data.sort[0].dir || this.pagedData.previousSort[0].field !== options.data.sort[0].field);

                            if (((this.pagedData.previousSort == undefined || this.pagedData.previousSort.length == 0) && options.data.sort) ||
                                ((options.data.sort == undefined || options.data.sort.length == 0) && this.pagedData.previousSort) ||
                                sortingHasChanged) {
                                this.pagedData.offset.start = 1;
                                this.pagedData.tokens.values = [];
                                this.pagedData.tokens.index = -1;
                                pageToken = null;
                            }
                            this.pagedData.previousSort = options.data.sort;
                            this.fetchPagedData(pageToken).then(
                                (response) => {
                                    _this.saveToHash(pageToken, this.pagedData, options.data.filter, options.data.sort);
                                    options.success(response);
                                },
                                (error) => {
                                    options.error(error);
                                }
                            );
                        }
                    },
                    schema: {
                        data: "items",
                        total: "totalCount",
                        model: {
                            id: "id",
                            fields: {}
                        }
                    }
                },
                pagedData: {
                    tokens: {
                        index: -1,
                        values: []
                    },
                    offset: {
                        start: 1,
                        end: undefined
                    },
                    limit: undefined,
                    overrideLimit: undefined,
                    totalCount: 0,
                    isFetching: undefined,
                    selectAll: false,
                    globalSelection: [],
                    isApplyingFilter: undefined,
                    searchClearOut: false,
                    previousSort: undefined
                },
                gridColumns: undefined,
                currentHash: undefined,
                searchValue: undefined,
                actionbar: undefined,
                portfolioSelector: undefined,
                selectedPortfolios: [],
                filterDropdownFields: []
            };
        },
        created: function () {
            this.gridColumns = this.buildColumns();
            this.pagedData.limit = this.setup.pageSizes.default;
            this.setup.selectAll = this.selectAll;
            this.setup.customFilter = this.customFilter;
            this.setup.refreshLayout = this.refreshLayout;
            this.setup.search = this.search;
            this.setup.globalSelection = this.globalSelection;
            this.setup.selectedCount = this.selectedCount;
            this.setup.totalCount = this.totalCount;
            this.setup.export = this.export;
            this.setup.refreshData = this.refreshData;
            this.setup.refreshCurrentPage = this.refreshCurrentPage
            this.setup.setActionbar = this.setActionbar;
            this.setup.getActiveFilters = this.getActiveFilters;
            this.setup.setActiveFilters = this.setActiveFilters;
            this.setup.getActiveSorting = this.getActiveSorting;
            this.setup.setActiveSorting = this.setActiveSorting;
            this.setup.getPortfolioSelectorValues = this.getPortfolioSelectorValues;
            this.setup.setPortfolioSelector = this.setPortfolioSelector;
            this.gridData.pageSize = this.setup.pageSizes.default;
            this.gridData.transport.pagedData = this.pagedData;
            this.gridData.transport.formatFilters = this.formatFilters;
            this.gridData.transport.fetchPagedData = this.fetchPagedData;
            this.gridData.transport.restoreFromHash = this.restoreFromHash;
            this.gridData.transport.saveToHash = this.saveToHash;
            kendo.ui.FilterMenu.fn.options.operators.string = {
                eq: "Is equal to",
                neq: "Is not equal to",
                startswith: "Starts with",
                contains: "Contains",
                doesnotcontain: "Does not contain",
                endswith: "Ends with",
                isnullorempty: "Is empty",
                isnotnullorempty: "Is not empty"

            };
            kendo.ui.FilterMenu.fn.options.operators.number = {
                eq: "Is equal to",
                neq: "Is not equal to",
                gte: "Is greater than or equal to",
                gt: "Is greater than",
                lte: "Is less than or equal to",
                lt: "Is less than",
                isnull: "Is empty",
                isnotnull: "Is not empty"

            };
            kendo.ui.FilterMenu.fn.options.operators.date = {
                eq: "Is equal to",
                neq: "Is not equal to",
                gte: "Is after or equal to",
                gt: "Is after",
                lte: "Is before or equal to",
                lt: "Is before",
                isnullorempty: "Is empty",
                isnotnullorempty: "Is not empty"
            };
        },
        mounted: function () {
            const _this = this;
            const grid = _this.$refs.grid.kendoWidget();
            if (!_this.pagedData.offset.end) {
                _this.pagedData.offset.end = _this.pagedData.limit;
            }

            const $pageSizeSelector = $("#pageSizeSelector");

            $pageSizeSelector.kendoDropDownList({
                dataSource: _this.setup.pageSizes.values,
                change: function () {
                    if (!_this.pagedData.isFetching) {
                        const newPageSize = parseInt($pageSizeSelector.val());
                        const pageToken = _this.hasPrevToken() ? _this.pagedData.tokens.values[_this.pagedData.tokens.index] : null;
                        // Only refetch the data when the page size is actually different.
                        if (newPageSize !== _this.pagedData.limit) {
                            _this.pagedData.limit = newPageSize;
                            _this.saveToHash(pageToken, _this.pagedData, grid.dataSource.filter(), grid.dataSource.sort());
                            _this.refreshData();
                        }
                    }
                }
            });

            window.onpopstate = function (event) {
                if (window.location.hash !== _this.currentHash) {
                    _this.restoreFromHash(true, true, true);
                }
            }
        },
        computed: {
            prevDisabled: function () {
                return !this.hasPrevToken() || this.pagedData.isFetching;
            },
            nextDisabled: function () {
                return !this.hasNextToken() || this.pagedData.isFetching;
            }
        },
        methods: {
            /**
             * Save current state to URL hash
             * @param {*} pageToken continuation token
             * @param {*} pagedData pagedData objec
             * @param {*} filter current filter
             * @param {*} sort current sort
             */
            saveToHash: async function (pageToken, pagedData, filter, sort) {
                const selectedPortfolios = this.portfolioSelector ? this.portfolioSelector.value() : undefined;
                const objectToHash = {
                    pageToken: pageToken,
                    pagedData: pagedData,
                    filter: filter,
                    sort: sort,
                    searchValue: this.searchValue,
                    selectedPortfolios: selectedPortfolios
                };
                const encodedHash = await encodeAndCompressToHash(objectToHash);
                this.currentHash = `#${encodedHash}`;
                
                if (!window.location.hash) {
                    history.replaceState(null, "", window.location.href + this.currentHash);
                }
                window.location.hash = encodedHash;
            },

            /**
             * 
             * @param {*} fetch fetch data if true, false if not
             * @param {*} filterAndSort whether filter and sort options should be retrieved from hash
             * @param {*} portfolioAndSearch whether portfolio filter and search text should be retrieved from hash
             * @param {*} options additional options
             * @returns  object restored from Hash
             */
            restoreFromHash: async function (fetch, filterAndSort, portfolioAndSearch, options) {
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                let objectFromHash = null;
                const encodedHash = window.location.hash.replace('#', '');
                if (encodedHash) {
                    objectFromHash = await decodeAndDecompressFromHash(encodedHash);

                    if (objectFromHash.pagedData) {
                        Object.assign(this.pagedData, objectFromHash.pagedData);
                    }
                    if (filterAndSort) {
                        if (objectFromHash.filter) {
                            grid.dataSource._filter = objectFromHash.filter;
                            if (options && options.data) {
                                options.data.filter = objectFromHash.filter
                            }
                        } else {
                            grid.dataSource._filter = undefined;
                        }
                        if (objectFromHash.sort) {
                            grid.dataSource._sort = objectFromHash.sort;
                            if (options && options.data) {
                                options.data.sort = objectFromHash.sort
                            }
                        } else {
                            grid.dataSource._sort = undefined;
                        }
                    }

                    if (portfolioAndSearch) {
                        _this.searchValue = objectFromHash.searchValue;
                        if (_this.actionbar) {
                            _this.seachValue = _this.seachValue ? _this.seachValue : '';
                            _this.actionbar.updateSearchText(_this.searchValue);
                        }
                        _this.selectedPortfolios = objectFromHash.selectedPortfolios;
                        if (_this.portfolioSelector && _this.selectedPortfolios) {
                            _this.setPortfolioSelector(_this.portfolioSelector);
                        }
                    }
                    if (fetch) {
                        this.fetchPagedData(objectFromHash.pageToken).then(
                            (response) => {
                                grid.dataSource.data(response.items);
                                _this.currentHash = window.location.hash;
                            },
                            (error) => {
                                console.error(error);
                            });
                    }
                }
                return objectFromHash;
            },
            
            /**
             * Refreshes the data in the grid and resets the selected page offset to the start.
             */
            refreshData: function () {
                const grid = this.$refs.grid.kendoWidget();

                this.pagedData.tokens = {
                    index: -1,
                    values: []
                };

                this.pagedData.offset = {
                    start: 1,
                    end: undefined
                };

                this.fetchPagedData().then(
                    (response) => {
                        grid.dataSource.data(response.items);
                    },
                    (error) => {
                        console.error(error);
                    });
            },

            refreshCurrentPage: function (clearSelection = false) {
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();

                const pageToken = this.pagedData.tokens.values[this.pagedData.tokens.index];

                this.fetchPagedData(pageToken, false).then(
                    (response) => {
                        grid.dataSource.data(response.items);
                        if (clearSelection) {
                            _this.pagedData.selectAll = false;
                            _this.pagedData.globalSelection = [];
                        }

                        _this.setup.renderFieldValues(_this.gridColumns);
                    },
                    (error) => {
                        console.error(error);
                    });
            },

            setActionbar: function (actionbar) {
                this.actionbar = actionbar;
                if (this.searchValue) {
                    this.actionbar.updateSearchText(this.searchValue);
                }
            },
            getPortfolioSelectorValues: function (portfolioSelector) {
                return this.portfolioSelector.value();
            },
            setPortfolioSelector: function (portfolioSelector) {
                const _this = this;
                _this.portfolioSelector = portfolioSelector;
                _this.portfolioSelector.value(_this.selectedPortfolios);
                const items = _this.portfolioSelector.listView.content.find("li");
                items.each(function () {
                    const checkbox = $(this).find("input[type='checkbox']");
                    const value = checkbox.val();
                    checkbox.prop("checked", _this.selectedPortfolios.indexOf(value) > -1);
                });
            },

            gridFilterMenuOpen: function (e) {
                const grid = this.$refs.grid.kendoWidget();    
                var kendoMultiSelect = e.container.find("input:eq(0)").data("kendoMultiSelect");
                if (kendoMultiSelect) {
                    const filter = grid.dataSource.filter();
                    if (filter) {
                        const value = filter.filters.find(x => x.field === e.field) ? filter.filters.find(x => x.field === e.field).value : kendoMultiSelect.value();
                        kendoMultiSelect.value(value);
                    }
                }
            },
            selectFilter: function (fieldName, element) {
                var _this = this;
                if (_this.filterDropdownFields.indexOf(fieldName) > -1) {
                    return;
                }
                _this.filterDropdownFields.push(fieldName);

                var filterEndpoint = this.setup.endpointFilterValues.indexOf("?") === -1 ? 
                    this.setup.endpointFilterValues + "?" : 
                    this.setup.endpointFilterValues + "&"; 

                var dataSource = new kendo.data.DataSource({
                    transport: {
                        read: {
                            url: filterEndpoint + "field=" + fieldName,
                            dataType: "json"
                        }
                    },
                });

                element.kendoMultiSelect({
                    dataSource: dataSource,
                    filter: "contains",
                    autoClose: false,
                    downArrow: true,
                    tagMode: "single",
                    noDataTemplate: "Select a value...",
                    itemTemplate: `<div class="checkbox">
                            #:data#
                            <input type="checkbox" name="checkbox" value"#:data#"/>
                            <span class="checkmark"></span>
                        </div>`,
                    change: function () {
                        const _this = this;
                        const selected = this.value();
                        const items = this.listView.content.find("li");
                        items.each(function (i) {
                            const checkbox = $(this).find("input[type='checkbox']");
                            const value = _this.dataSource.data()[i];
                            checkbox.prop("checked", selected.indexOf(value) > -1);
                        });
    
                        if (selected.length == 0) {
                            this.tagList.children().each(function() {
                                this.remove();
                            });
                        }
                    },
                    open: function () {
                        const _this = this;
                        const selected = this.value();
                        const items = this.listView.content.find("li");
                        items.each(function (i) {
                            const checkbox = $(this).find("input[type='checkbox']");
                            const value = _this.dataSource.data()[i];
                            checkbox.prop("checked", selected.indexOf(value) > -1);
                        });
                    }
                });
            },

            /**
             * Builds the list of grid columns using the supplied grid configuration setup.
             * This method calls the configurable renderFieldValues() and renderRowActions() for embedding custom rendering and/or actions.
             * @returns A list of grid columns
             */
            buildColumns: function () {
                const _this = this;
                let columns = [];

                if (!_this.setup.config.SelectedColumns || _this.setup.config.SelectedColumns.length < 1) {
                    columns = _this.setup.config.AvailableColumns;
                } else {
                    columns = _this.setup.config.SelectedColumns;
                }

                columns = columns.map(function (m) {
                    let filterableDefinition = !m.PropertyName.startsWith('computedFields');

                    if (_this.setup.config.ColumnsWithDropdownInFilter.indexOf(m.PropertyName) > -1) {
                        filterableDefinition = { extra: false, ui: _this.selectFilter.bind(this, m.PropertyName) };
                    }

                    return {
                        'field': m.PropertyName,
                        'title': (hasBranding ? m.DisplayName : m.DisplayName.replace("DLL", "Leased")),
                        'filterable': filterableDefinition,
                        'format': m.PropertyType == 'date' ? "{0:yyyy/MM/dd}" : '',
                        'type': m.PropertyType
                    };
                });
                _this.setup.config.SelectedColumns.map(function (m) {
                    _this.gridData.schema.model.fields[m.PropertyName] = { 'type': m.PropertyType };
                });

                if (_this.setup.enableMultiSelect) {
                    columns.unshift({
                        'field': 'selected',
                        'title': ' ',
                        'filterable': false,
                        'sortable': false,
                        'headerTemplate':
                            `<div>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'template':
                            `<div #= selected ? \'class="state-selected"\' : "" #>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" #= selected ? \'checked="checked"\' : "" # />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'width': '40px'
                    });
                }

                if (_this.setup.renderFieldValues) {
                    columns = _this.setup.renderFieldValues(columns);
                }

                if (_this.setup.renderRowActions) {
                    const customActions = _this.setup.renderRowActions();

                    if (customActions) {
                        customActions.forEach((x) => {
                            columns.push(x);
                        });
                    }
                }

                return columns;
            },

            /**
             * Toggles between selecting all records in grid (on all pages) or deselecting all records.
             * Previous selections on any page is cleared out.
             * When selectAll is not on then globalSelection contains all items that are manually selected by uses
             * When selectAll is on then globalSelection contains all items that are manuallly deslected by user
             */
            selectAll: function () {
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                this.pagedData.selectAll = !this.pagedData.selectAll;
                this.pagedData.globalSelection = [];
                grid.dataSource.data().forEach(item => {
                    item.selected = _this.pagedData.selectAll;
                    _this.addOrRemoveFromGlobalSelection(item, item.selected);
                });
                _this.setHeaderSelectAll(grid, this.pagedData.selectAll);
                grid.refresh();
            },

            setHeaderSelectAll: function (grid, checked)
            {
                const cb = grid.element[0].querySelector(".k-grid-header .visual-checkbox input[type='checkbox']");
                cb.checked = checked;
                if (cb.checked) {
                    $(cb.parentElement.parentElement).addClass("state-selected");

                } else {
                    $(cb.parentElement.parentElement).removeClass("state-selected");
                }
            },

            /**
             * Perfors global search by filtering on all columns
             */
            search: function (e) {
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.transport.pagedData.isApplyingFilter = true;
                let filter = {};
                if (e !== null && e !== '') {
                    this.searchValue = e.trim();
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: _this.searchValue
                            });
                        }
                    });
                } else {
                    this.searchValue = ''
                    this.pagedData.searchClearOut = true;
                }
                grid.dataSource.filter(filter);
            },

            export: function () {
                const grid = this.$refs.grid.kendoWidget();
                this.pagedData.overrideLimit = this.pagedData.totalCount;
                grid.options.excel.fileName = "Asset Export.xlsx";
                grid.saveAsExcel();
            },

            /**
             * Retrieves global selection list, either all selected records or unselected records (when selectAll is checked) and whether selectAll is toggled on or off.
             * @returns when selectAll is unchecked it will return all records that have been selected, when selectAll is checked it will return all records that have been unselected
             */
            globalSelection: function () {
                const grid = this.$refs.grid.kendoWidget();
                return {
                    selectAll: this.pagedData.selectAll,
                    items: this.pagedData.globalSelection,
                    limit: this.pagedData.limit,
                    filter: grid.dataSource.filter(),
                    sort: grid.dataSource.sort()
                };
            },

            /**
             * Determines the count of selected items. When select all is toggled on then global selection length is deducted from tatalCount because global selection contains the unselected items.
             * @returns Selected items count. 
             */
            selectedCount: function () {
                return this.pagedData.selectAll ? this.pagedData.totalCount - this.pagedData.globalSelection.length : this.pagedData.globalSelection.length;
            },

            /**
             * Determines the count of all items.
             * @returns Total items count. 
             */
            totalCount: function () {
                return this.pagedData.totalCount;
            },

            /**
             * Whether there is previous page available or not.
             * @returns True if there is a previous page, else false.
             */
            hasPrevToken: function () {
                return this.pagedData.tokens.index >= 0;
            },

            /**
             * Whether there is next page available or not.
             * @returns True if there is a next page, else false.
             */
            hasNextToken: function () {
                return this.pagedData.tokens.values.length > 0 &&
                    (this.pagedData.tokens.index + 1) < (this.pagedData.tokens.values.length)
            },

            /**
             * Navigates to the previous page in the dataset.
             * @param {*} evt Representing the origin of the event.
             */
            goToPrevPage: function (evt) {
                evt.preventDefault();
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                if (this.hasPrevToken()) {
                    this.pagedData.tokens.values.splice(this.pagedData.tokens.index + 1, 1);

                    this.pagedData.tokens.index -= 1;
                    const pageToken = this.pagedData.tokens.values[this.pagedData.tokens.index];

                    this.fetchPagedData(pageToken, true).then((response) => {
                        grid.dataSource.data(response.items);
                        _this.calculateOffset(false);

                        _this.saveToHash(pageToken, _this.pagedData, grid.dataSource.filter(), grid.dataSource.sort());
                    });
                }
            },

            /**
             * Navigates to the next page in the dataset.
             * @param {*} evt Representing the origin of the event.
             */
            goToNextPage: function (evt) {
                evt.preventDefault();
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                if (this.hasNextToken()) {
                    this.pagedData.tokens.index += 1;

                    const pageToken = this.pagedData.tokens.values[this.pagedData.tokens.index];
                    this.fetchPagedData(pageToken, false).then((response) => {
                        grid.dataSource.data(response.items);
                        _this.calculateOffset(true);

                        _this.saveToHash(pageToken, _this.pagedData, grid.dataSource.filter(), grid.dataSource.sort());
                    });
                }
            },

            /**
             * Calculates new offset values representing the position within the dataset.
             * @param {*} forward Whether this re-calculation is a result of a forward action or not.
             */
            calculateOffset: function (forward) {
                let newOffset = {};

                if (forward) {
                    newOffset = {
                        start: this.pagedData.offset.start + this.pagedData.limit,
                        end: undefined
                    };

                    newOffset.end = (newOffset.start + this.pagedData.limit) - 1;

                    if (newOffset.end > this.pagedData.totalCount) {
                        newOffset.end = this.pagedData.totalCount;
                    }
                }
                else {
                    newOffset = {
                        start: undefined,
                        end: (this.pagedData.offset.start - 1)
                    };

                    newOffset.start = this.pagedData.offset.start - this.pagedData.limit;
                }

                Object.assign(this.pagedData.offset, newOffset);
            },

            /**
             * Performs some pre-processing before the filters are sent to the server.
             * @param {*} filterRoot Root filter instance of the kendo grid.
             * @returns Modified filter root instance
             */
            formatFilters: function (filterRoot) {
                if (!filterRoot)
                    return;

                const internalFormatter = function (filters) {
                    filters.forEach((x) => {
                        // Force datetime values to use the format 'yyyy-MM-dd'
                        if (x.field && (x.field.endsWith("Date") || x.field.endsWith("Date\"]"))) {
                            x.value = formatDateTimeToDate(x.value);
                        }
                        else if (x.value && typeof x.value === 'string' && x.value.includes(",")) {
                            x.value = x.value.split(",");
                        }
                        else if (x.filters) {
                            internalFormatter(x.filters);
                        }
                    });
                }

                internalFormatter(filterRoot.filters);
            },

            /**
             * Update pageSizeSelector on navigation (browser-back, -forward and -refresh)
             */
            setPageSizeSelector: function () {
                const $pageSizeSelector = $("#pageSizeSelector");
                const pageSizeSelectorValue = parseInt($pageSizeSelector.val());
                if (pageSizeSelectorValue !== this.pagedData.limit) {
                    $pageSizeSelector.val(this.pagedData.limit);
                    // Bit of a ugly hack. Kendo doesn't update DDL value on browser-back and -forward, only on browser-refresh!
                    const inputSpan = $('#queryable-grid div.k-grid-pager span.k-input');
                    if (inputSpan.length > 0) {
                        inputSpan[0].innerHTML = this.pagedData.limit;
                    }
                }
            },

            /**
             * Fetches the paged dataset from the remote endpoint.
             * @param {*} pageToken Represents the page token to use for this fetch
             * @param {*} previos Whether previous page is feteched
             * @returns Empty Promise
             */
            fetchPagedData: function (pageToken, previous) {
                const _this = this;
                const _previous = previous;
                const grid = this.$refs.grid.kendoWidget();
                const filter = grid.dataSource.filter();
                const sort = grid.dataSource.sort();
                return new Promise((resolve, reject) => {
                    _this.pagedData.isFetching = true;
                    _this.toggleGridLoader(true);
                    _this.setPageSizeSelector();
                    _this.formatFilters(grid.dataSource.filter())
                    const limit = !_this.pagedData.overrideLimit ?  _this.pagedData.limit : _this.pagedData.overrideLimit;
                    if (_this.pagedData.overrideLimit) {
                        _this.pagedData.overrideLimit = undefined;
                    }
                    axios.post(
                        _this.setup.endpointQuery,
                        {
                            token: pageToken,
                            limit: limit,
                            filter: filter,
                            sort: sort
                        }).catch(error => {
                            _this.toggleGridLoader(false);
                            reject(error);
                        }).then(response => {
                            if (response) {
                                if (response.data.pageToken && !_previous) {
                                    _this.pagedData.tokens.values.push(response.data.pageToken);
                                }

                                _this.pagedData.totalCount = response.data.totalCount;
                                _this.pagedData.isFetching = false;

                                if (response.data.totalCount > _this.pagedData.limit) {
                                    _this.pagedData.offset.end = (_this.pagedData.offset.start + _this.pagedData.limit) - 1;
                                }
                                else {
                                    _this.pagedData.offset.end = response.data.totalCount;
                                }

                                if (_this.setup.enableMultiSelect) {
                                    response.data.items.forEach(item => {
                                        item.selected = _this.pagedData.selectAll != (_this.pagedData.globalSelection.findIndex(selection => selection.id == item.id) > -1);
                                    });
                                }
                                _this.toggleGridLoader(false);
                                resolve(response.data);
                            }
                        });
                });
            },

            /**
             * Toggles the visibility of the grid loading indicator.
             * @param {*} show True if you want to show the loading indicator, else false.
             */
            toggleGridLoader: function (show) {
                kendo.ui.progress($("#queryable-grid-table"), show);
            },

            /**
             * Add or remove an item from global selection list. When select all is toggled on then global selection contains the unselected items
             * @param {*} item Item that is to be added or removed.
             * @param {*} checked True if the item is to be added, false when it is to be removed.
             */
            addOrRemoveFromGlobalSelection(item, checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    if (!this.pagedData.selectAll)
                        this.pagedData.globalSelection.push(item);
                    else
                        this.pagedData.globalSelection.splice(this.pagedData.globalSelection.indexOf(item), 1);
                } else {
                    if (!this.pagedData.selectAll)
                        this.pagedData.globalSelection.splice(this.pagedData.globalSelection.indexOf(item), 1);
                    else
                        this.pagedData.globalSelection.push(item);
                }

                this.setup.rowSelectedEvent(item, checked);
                grid.refresh();
            },

            /**
             * Bounds custom event handlers to various grid elements.
             * @param {*} e Representing the origin of the event.
             */
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                const _this = this;

                grid.element.unbind('dblclick');

                // Render title attribute containing the full title of the column.
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {
                        $(element).attr('title', title);
                    }
                });

                // Selections of rows using the checkboxes
                if (_this.setup.enableMultiSelect &&
                    _this.setup.rowSelectedEvent) {
                    grid.element.on(
                        'click',
                        '.visual-checkbox',
                        function (e) {
                            e.preventDefault();
                            e.stopImmediatePropagation();

                            const currentItem = grid.dataItem(e.currentTarget.closest('tr[data-uid]'));
                            if (currentItem) {
                                currentItem.selected = !currentItem.selected;
                                _this.addOrRemoveFromGlobalSelection(currentItem, currentItem.selected);
                            }
                            else {
                                const cb = this.querySelector("input[type='checkbox']");
                                cb.checked = !cb.checked;
                                if (cb.checked) {
                                    $(this.parentElement).addClass("state-selected");
                                } else {
                                    $(this.parentElement).removeClass("state-selected");
                                }
                                grid.dataSource.data().forEach(item => {
                                    item.selected = cb.checked;
                                    _this.addOrRemoveFromGlobalSelection(item, item.selected);
                                });
                            }
                        });
                }

                // Double click on full row
                if (_this.setup.rowClickEvent) {
                    grid.element.on(
                        'dblclick',
                        'tbody tr[data-uid]',
                        function (e) {
                            e.preventDefault();
                            e.stopImmediatePropagation();

                            const currentItem = grid.dataItem(e.currentTarget)
                            _this.setup.rowClickEvent(currentItem);
                        });
                }

                // Single click on any of the optionally specified custom-actions
                if (_this.setup.rowActionClickEvent) {
                    grid.element.on('click', '.custom-action',
                        function (e) {
                            e.preventDefault();

                            const currentItem = grid.dataItem(e.currentTarget.closest('tr'));
                            if (currentItem) {
                                const type = e.currentTarget.dataset.type;
                                _this.setup.rowActionClickEvent(currentItem, type);
                            }
                        });
                }

                _this.gridOnChange(e);
            },

            /**
             * Bounds custom event handlers to various grid elements.
             * @param {*} e Representing the origin of the event.
             */
            gridOnChange: function (e) {
                const _this = this;
                const grid = _this.$refs.grid.kendoWidget();
                if (_this.setup.gridChangedEvent) {
                    var selectedItems = _this.pagedData.globalSelection;
                    if (_this.pagedData.selectAll) { 
                        // When select all is toggled on, the items on globalSelection are the unselected items. These need to be filtered out.
                        selectedItems = grid.dataSource.data().filter(item => _this.pagedData.globalSelection.findIndex(selection => selection.id == item.id) == -1);
                    }
                    _this.setup.gridChangedEvent(selectedItems);
                }
            },
            gridOnFilter: function (e) {
                const _this = this;
                const grid = _this.$refs.grid.kendoWidget();
                grid.dataSource.transport.pagedData.isApplyingFilter = true;
                _this.pagedData.selectAll = false;
                _this.pagedData.globalSelection = [];
                if (_this.setup.gridFilteredEvent) {
                    _this.setup.gridFilteredEvent(e);
                }
                grid.refresh();
            },
            gridColumnOnReorder: function(e){
                if (this.setup.gridColumnReorderEvent){
                    this.setup.gridColumnReorderEvent(e.column.field, e.newIndex, e.oldIndex);
                }
            },
            customFilter: function (data) {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.transport.pagedData.isApplyingFilter = true;
                const currentFilters = grid.dataSource.filter();

                let newFilters = {
                    logic: "and",
                    filters: []
                };

                newFilters.filters.push(data);
                if (currentFilters) {
                    const filtersToKeep = currentFilters.filters.filter(filter => filter.field);
                    newFilters = newFilters.filters.concat(filtersToKeep);
                }

                grid.dataSource.filter(newFilters);
            },
            getActiveFilters: function () {
                const grid = this.$refs.grid.kendoWidget();
                const currentFilters = grid.dataSource.filter();
                return currentFilters ?? {};
            },
            setActiveFilters: function (data) {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.filter(data);
            },
            getActiveSorting: function () {
                const grid = this.$refs.grid.kendoWidget();
                return grid.dataSource.sort();
            },
            setActiveSorting: function (data) {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.sort(data);
            },
            refreshLayout: function () {
                const grid = this.$refs.grid.kendoWidget();
                const gridOptions = grid.getOptions();
                gridOptions.columns = this.buildColumns();

                grid.setOptions(gridOptions);
                this.refreshData();
            }
        }
    });
